import 'alpinejs'
import './tiger';
import responsiveIframe from './_components/responsive-iframe';

import { createPopper } from '@popperjs/core/lib/popper-lite';
import flip from '@popperjs/core/lib/modifiers/flip';
import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
import Swiper from 'swiper';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';
SwiperCore.use([Navigation, Pagination]);

document.querySelectorAll('.js-swiper-slider').forEach(el => {
  const swiper = new Swiper(el, {
    direction: 'horizontal',
    loop: true,
    pagination: {
      el: el.querySelector('.swiper-pagination'),
    },
    navigation: {
      nextEl: el.querySelector('.swiper-button-next'),
      prevEl: el.querySelector('.swiper-button-prev'),
    },
  });
});

document.querySelectorAll('.popper').forEach(el => {
  const children = el.parentElement.querySelectorAll('.popper-child');
  children.forEach(child => {
    const placement = child.dataset.position ?? 'bottom';
    const popper = createPopper(el, child, {
      modifiers: [
        flip,
        preventOverflow,
        {
          name: 'placement',
          enabled: true,
          phase: 'main',
          fn({ state }) {
            state.options.placement = placement;
            if (state.placement.startsWith('right') || state.placement.startsWith('left')) {
              const mediaQuery = 'matchMedia' in window ? window.matchMedia('(max-width: 1000px)') : null;
              if (mediaQuery?.matches) {
                state.options.placement = 'bottom';
              }
            }
          },
        }
      ],
      strategy: 'absolute',
      placement: placement
    });

    child.classList.add('hidden');
    child.classList.remove('invisible');

    el.addEventListener('update', () => {
      child.classList.toggle('hidden');
      popper.update();
    });
  });
});

responsiveIframe.iframeListener();
