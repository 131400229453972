export default {
  selected: {
    format: {},
    genre: {},
    sort: {},
    tag: {},
    lists: {}
  },
  entries: [],
  show: false,
  filterExpanded: false,
  sortByExpanded: false,
  
  isStatic(uid) {
    if (!uid) return false;
    
    return this.entries.includes(uid);
  },

  isSelected(slug, type, uid) {
    return (this.selected[type] && this.selected[type][slug]) || this.isStatic(uid);
  },
  
  select(slug, type, event) {
    if (!this.selected[type]) {
      this.selected[type] = {};
    }

    this.selected[type][slug] = !this.selected[type][slug] ? slug : null;

    this.refresh();
  },

  sort(value, type, event) {
    this.selected['sort'] = this.selected['sort'] ? this.selected['sort'] : [];
    this.selected['sort'][type] = value;
    console.log(this.selected['sort']);
    this.refresh();
  },

  refresh() {
    const values = {};
    for (const k in this.selected) {
      const elm = document.querySelectorAll('input[name="' + k + '"]');
      if (elm.length > 0) {
        elm.forEach(el => {
          el.value = this.selectedValues(k).join('~~');
        });

      } else {
        console.error('Input with name ' + k + ' not found');
      }

    }

    htmx.trigger('#product-listing', "refresh");
  },

  selectedValues(type) {
    if (!this.selected[type]) {
      this.selected[type] = {};
    }

    return Object.values(this.selected[type]).filter(x => x);
  },

  setInitialValues(input) {
    const values = JSON.parse(input) ?? {};
    for (const [ key, value ] of Object.entries(values)) {
      this.selected[key] = value;
    }
  },

  updateValues(input) {
    this.setInitialValues(input);
  },

  setEntries(...entries) {
    this.entries = [];
    for (const [key, entry] of Object.entries(entries)) {
      this.entries = [
        ...this.entries,
        ...(entry ?? []).filter(x => x)
      ]
    }
  }
}