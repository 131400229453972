import searchFilters from './_components/search-filter';
import { createApp  } from 'vue/dist/vue.esm-bundler'

const tiger = {
  searchFilters
};

window.tiger = tiger;

// VUE setup.

// Importing all components
const modules = import.meta.glob('./_vue-components/*', { eager: true })
const components = {}
for (const path in modules) {
  components[modules[path].default.__name] = modules[path].default
}

console.log(modules)
console.log(components)

// instantiate the Vue apps
// Note our lookup is a wrapping div with .vue-app class

for (const el of document.getElementsByClassName('vue-app')) {
  createApp({
    template: el.innerHTML,
    components
  }).mount(el)
}

